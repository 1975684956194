<!-- <template>
  <div class="game-container layout-border">
    <ClickTrainGame
      :strokeHanzi="strokeHanzi"
      :gameIndex="gameIndex"
      :hanziStrokeInfo="hanziStrokeInfo"
      :lightIndex="lightIndex"
      :shadowImg = "shadowImg"
      :headerBgImg = "headerBgImg"
      :strokePinyin="strokePinyin"
    />
  </div>
</template>

<script>
import ClickTrainGame from "@/components/Course/GamePage/ClickTrainGame";
export default {
  data() {
    return {
      headerBgImg:{
        defaultImg:require("@/assets/img/16-GAMES/G16-dynamo-machine/0-bulb-on.svg"),
        firstImg:require("@/assets/img/16-GAMES/G16-dynamo-machine/1-bulb-on.svg"),
        secondImg:require("@/assets/img/16-GAMES/G16-dynamo-machine/2-bulb-on.svg"),
        thirdImg:require("@/assets/img/16-GAMES/G16-dynamo-machine/3-bulb-on.svg"),
      },
      shadowImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/cloud.svg"),
      strokeHanzi: "中国早饭",
      strokePinyin: "Zhōngguó zǎofàn",
      gameIndex: 1,
      lightIndex: [1, 2, 3],
      hanziStrokeInfo: [
        {
          strokeBgImg: require("@/assets/img/16-GAMES/G69-culture-train/l2-lesson-02/1-baozi.svg"),
        },
        {
          strokeBgImg: require("@/assets/img/16-GAMES/G69-culture-train/l2-lesson-02/2-jiaozi.svg"),
        },
        {
          strokeBgImg: require("@/assets/img/16-GAMES/G69-culture-train/l2-lesson-02/3-kuaizi.svg"),
        },
      ],
    };
  },
  components: {
    ClickTrainGame,
  },
};
</script>

<style lang="scss" scoped>
</style> -->
<template>
  <div class="game-container">
    <ChooseStructureGame :optionList="optionList" :imgList="imgList" :titleInfo="titleInfo" />
  </div>
</template>

<script>
import ChooseStructureGame from "@/components/Course/GamePage/ChooseStructureGame";
export default {
  data() {
    return {
      titleInfo:{
        pinyin:"zuǒyòu jiégòu",
        hanzi:"左右结构"
      },
      optionList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-ni.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-hao.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-ma.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-gou.svg"),
        },
       {
          id: 5,
          image: require("@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-hen.svg"),
        },
      ],

      imgList: [
        [{
          id: 1,
          pinying: "nǐ",
          hanzi: "你",
        },
        {
          pinying: "shì",
          hanzi: "是",
        },
        {
          pinying: "lǎo",
          hanzi: "老",
        },
        ],
        [{
          pinying: "ài",
          hanzi: "爱",
        },
        {
          pinying: "bà",
          hanzi: "爸",
        },
        {
          id: 2,
          pinying: "hǎo",
          hanzi: "好",
        },
        ],
        [{
          pinying: "kàn",
          hanzi: "看",
        },
          {
          id: 3,
          pinying: "mā",
          hanzi: "妈",
        },
        {
          pinying: "guó",
          hanzi: "国",
        },
        ],
        [{
          pinying: "ěr ",
          hanzi: "耳",
        },
        {
          id: 4,
          pinying: "gǒu",
          hanzi: "狗",
        },
        {
          pinying: "niǎo",
          hanzi: "鸟",
        },
        ],
        [{
          id: 5,
          pinying: "hěn",
          hanzi: "很",
        },
        {
          pinying: "tóu",
          hanzi: "头",
        },
        {
          pinying: "miàn",
          hanzi: "面",
        },
        ],
      ],
    };
  },
  components: {
    ChooseStructureGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











