<template>
  <div class="game-container">
    <PhoneGame
      :answerList="answerList"
    />
  </div>
</template>

<script>
import PhoneGame from "@/components/Course/GamePage/PhoneGame";
export default {
  data() {
    return {
      answerList: [
        {
          answerInfo:"请坐",
          answerPin:"qǐng zuò",
          audioSrc: require("@/assets/audio/a-zh/a/a1.mp3"),
          rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
          allImgList: [
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-done.svg`),
              id:1,
              pinyin:"kě",
              hanzi:"可",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-done.svg`),
              id:2,
              pinyin:"bù",
              hanzi:"不",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-done.svg`),
              id:3,
              pinyin:"yǐ",
              hanzi:"以",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-done.svg`),
              id:4,
              pinyin:"qǐng",
              hanzi:"请",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-done.svg`),
              id:5,
              pinyin:"kè",
              hanzi:"客",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-done.svg`),
              id:6,
              pinyin:"qi",
              hanzi:"气",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-done.svg`),
              id:7,
              pinyin:"zuò",
              hanzi:"坐",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
            
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
            },
          ],
        },
        {
          answerInfo:"可以",
          answerPin:"kěyǐ ",
          audioSrc: require("@/assets/audio/a-zh/a/a1.mp3"),
          rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/keyi.svg`),
          allImgList: [
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-done.svg`),
              id:1,
              pinyin:"kě",
              hanzi:"可",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-done.svg`),
              id:2,
              pinyin:"bù",
              hanzi:"不",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-done.svg`),
              id:3,
              pinyin:"yǐ",
              hanzi:"以",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-done.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-done.svg`),
              id:4,
              pinyin:"qǐng",
              hanzi:"请",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-done.svg`),
              id:5,
              pinyin:"kè",
              hanzi:"客",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-done.svg`),
              id:6,
              pinyin:"qi",
              hanzi:"气",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-done.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-done.svg`),
              id:7,
              pinyin:"zuò",
              hanzi:"坐",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
            
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
            },
          ],
        },
        {
          answerInfo:"不客气",
          answerPin:"bú kèqi",
          audioSrc: require("@/assets/audio/a-zh/a/a1.mp3"),
          rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/bukeqi.svg`),
          allImgList: [
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-done.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-done.svg`),
              id:1,
              pinyin:"kě",
              hanzi:"可",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-done.svg`),
              id:2,
              pinyin:"bù",
              hanzi:"不",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-done.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-done.svg`),
              id:3,
              pinyin:"yǐ",
              hanzi:"以",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-done.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-yellow.svg`),
              id:4,
              pinyin:"qǐng",
              hanzi:"请",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-done.svg`),
              id:5,
              pinyin:"kè",
              hanzi:"客",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-done.svg`),
              id:6,
              pinyin:"qi",
              hanzi:"气",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-done.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-yellow.svg`),
              id:7,
              pinyin:"zuò",
              hanzi:"坐",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
            
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
            },
          ],
        },
      ],
    };
  },
 
  components: {
    PhoneGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>