<template>
  <div class="stroke-path-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @click.native="nextPage"
      v-if="isLastStep"
    ></PageButton>
    <div class="stroke-path-game-box">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="currentStrokeType + 1"
        @changeTheNumbers="changeTheNumbers"
        class="thumb-nails"
      >
      </SceneSwitchThumbnails>

      <div class="stroke-path-game-content">
        <!-- begin area -->
        <div class="puzzle-game-begin" :class="'lesson-begin-' + lessonNum">
          <div class="little-bear">
            <img
              @click.stop="changeAnimal(0)"
              :src="firstAnimalInfo.img"
              alt=""
              class="first-animal"
              :class="{ 'border-blue': currentStrokeType === 0 }"
            />
            <img
              class="arrow"
              src="@/assets/img/16-GAMES/G18-maze2/arrow-blue.svg"
              alt=""
            />
          </div>
        </div>

        <!-- game area -->
        <div class="puzzle-game-content">
          <div class="puzzle-game-box" ref="puzzle">
            <img :src="puzzleBgImg" alt="" class="puzzle-game-img" id="puzzle-game-img" ref="puzzledRectImg"/>
          </div>
          <div
            class="avatar-move-area"
            :style="{ height: gameHeight + 'px', width: gameWidth + 'px' }"
          >
            <div
              class="col-area"
              v-for="(item, index) in puzzleItemList"
              :key="index + 'row'"
            >
              <div
                class="row-area"
                v-for="(ele, index) in item"
                :key="index + 'col'"
                :class="{ black: ele.type === 1 }"
              >
                <!-- {{ item.type }} -->
                <div class="avatar-img">
                  <transition name="el-fade-in">
                    <img
                      :src="firstAnimalInfo.img"
                      class="border-blue"
                      alt=""
                      v-if="currentStrokeType === 0 && ele.isShow"
                    />
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- final area -->
        <div class="puzzle-game-final" :class="'lesson-' + lessonNum">
          <div class="little-bear">
            <img
              class="arrow"
              src="@/assets/img/16-GAMES/G18-maze2/arrow-blue.svg"
              alt=""
            />
            <img :src="firstAnimalInfo.finalImg" alt="" />
          </div>
        </div>
      </div>

      <!-- control button area -->
      <div class="button-control-box">
        <img
          src="@/assets/img/16-GAMES/G18-maze2/control.svg"
          alt=""
          class="control-bg-img"
        />
        <!-- instructionsDisplay -->
        <div class="instructions" @click.stop="rightClick" v-show="false">
          <img
            class="blinkAndDisappear"
            src="@/assets/img/17-Tutorials/hand.svg"
            alt=""
          />
        </div>

        <div class="button-control-content">
          <div
            class="button-img"
            @click.stop="leftClick()"
            @mouseover="leftImg = 2"
            @mouseleave="leftImg = 1"
          >
            <img
              v-show="leftImg == 1"
              src="@/assets/img/16-GAMES/G18-maze2/left-icon.svg"
              alt=""
            />
            <img
              v-show="leftImg == 2"
              src="@/assets/img/16-GAMES/G18-maze2/left-icon-hover.svg"
              alt=""
            />
          </div>
          <div
            class="button-img"
            @click.stop="rightClick()"
            @mouseover="rightImg = 2"
            @mouseleave="rightImg = 1"
          >
            <img
              v-show="rightImg == 1"
              src="@/assets/img/16-GAMES/G18-maze2/right-icon.svg"
              alt=""
            />
            <img
              v-show="rightImg == 2"
              src="@/assets/img/16-GAMES/G18-maze2/right-icon-hover.svg"
              alt=""
            />
          </div>
          <div
            class="button-img"
            @click.stop="upClick()"
            @mouseover="upImg = 2"
            @mouseleave="upImg = 1"
          >
            <img
              v-show="upImg == 1"
              src="@/assets/img/16-GAMES/G18-maze2/up-icon.svg"
              alt=""
            />
            <img
              v-show="upImg == 2"
              src="@/assets/img/16-GAMES/G18-maze2/up-icon-hover.svg"
              alt=""
            />
          </div>
          <div
            class="button-img"
            @click.stop="downClick()"
            @mouseover="downImg = 2"
            @mouseleave="downImg = 1"
          >
            <img
              v-show="downImg == 1"
              src="@/assets/img/16-GAMES/G18-maze2/down-icon.svg"
              alt=""
            />
            <img
              v-show="downImg == 2"
              src="@/assets/img/16-GAMES/G18-maze2/down-icon-hover.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";

export default {
  components: {
    PageButton,
    SceneSwitchThumbnails,
  },
  props: {
    buttonList: {
      type: Array,
      require: true,
    },
    firstAnimalInfo: {
      type: Object,
      require: true,
    },
    puzzleBgImg: {
      type: String,
      require: true,
    },
    puzzleItemList: {
      type: Array,
      require: true,
    },
    strokeStepsArr: {
      type: Array,
      require: true,
    },
    lessonNum: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      shake: 1,
      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),
      instructionsDisplay: true,
      numBers: 1,
      moveNumber: 0,

      leftImg: 1,
      rightImg: 1,
      upImg: 1,
      downImg: 1,
      close: true,
      isLastStep: false,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "next",
            startNumberRange: 1,
            endNumberRange: 1,
            clickGoToNextPage: true,
          },
        ],
      },

      gameWidth: null,
      gameHeight: null,

      currentStrokeType: 0,

      rowIndex: 3,
      colIndex: 3,

      lock: false,
      currentSteps: 0,
      firstActived: true,
      secondActived: false,
      changeAnimalValue: false,
      changeAnimalInfo: {},
      leftSocket: null,
      rightSocket: null,
      upSocket: null,
      upClickValue: false,
      downClickValue: false,
      leftClickValue: false,
      rightClickValue: false,
      downSocket: null,
    };
  },
  mounted() {
    window.addEventListener("resize", this.resizeGame);
    this.resizeGame();
    this.$bus.$on("clickChooseAnimal", ({ type }) => {
      this.changeAnimal(type, 1);
    });
    this.$bus.$on("clickUp", () => {
      this.upClick(1);
    });
    this.$bus.$on("clickDown", () => {
      this.downClick(1);
    });
    this.$bus.$on("clickLeft", () => {
      this.leftClick(1);
    });
    this.$bus.$on("clickRight", () => {
      this.rightClick(1);
    });
  },
  beforeDestroy() {
    clearTimeout(this.timer);
    this.$bus.$off("clickChooseAnimal");
    this.$bus.$off("clickUp");
    this.$bus.$off("clickDown");
    this.$bus.$off("clickLeft");
    this.$bus.$off("clickRight");
    window.removeEventListener("resize", this.resizeGame);
  },
  watch: {
    changeAnimalInfo: {
      handler(value) {
        if (!this.changeAnimalValue) {
          const data = {
            clickType: 2001501,
            data: { value },
            text: "StrokePathGame点击socket",
          };
          this.sendSocketInfo = { ...data };
          this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        }
      },
      deep: true,
    },
    upSocket: {
      handler(value) {
        if (!this.upClickValue) {
          const data = {
            clickType: 2001502,
            data: { value },
            text: "StrokePathGame点击socket",
          };
          this.sendSocketInfo = { ...data };
          this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        }
      },
      deep: true,
    },
    downSocket: {
      handler(value) {
        if (!this.downClickValue) {
          const data = {
            clickType: 2001503,
            data: { value },
            text: "StrokePathGame点击socket",
          };
          this.sendSocketInfo = { ...data };
          this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        }
      },
      deep: true,
    },
    leftSocket: {
      handler(value) {
        if (!this.leftClickValue) {
          const data = {
            clickType: 2001504,
            data: { value },
            text: "StrokePathGame点击socket",
          };
          this.sendSocketInfo = { ...data };
          this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        }
      },
      deep: true,
    },
    rightSocket: {
      handler(value) {
        console.log(value,this.rightClickValue,9999)
        if (!this.rightClickValue) {
          const data = {
            clickType: 2001505,
            data: { value },
            text: "StrokePathGame点击socket",
          };
           console.log(data,33)
          this.sendSocketInfo = { ...data };
          this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        }
      },
      deep: true,
    },
  },
  methods: {
    resizeGame(e) {
    const puzzledRectImg = this.$refs.puzzledRectImg.getBoundingClientRect();
    this.gameWidth = puzzledRectImg.width;
    this.gameHeight = puzzledRectImg.height;
    },
    // 切换动物游戏
    changeAnimal(type, stype) {
      if (stype != 1) {
        this.changeAnimalValue = false;
      } else {
        this.changeAnimalValue = true;
      }
      if (type !== this.currentStrokeType) {
        this.initGame(type);
      }
      this.changeAnimalInfo = { type };
    },
    initGame(type) {
      this.$emit("initGame");
      if (type) {
        this.currentStrokeType = 1;
        if (this.lessonNum === 66) {
          this.colIndex = 3;
        } else {
          this.colIndex = 0;
        }
        this.firstActived = false;
        this.secondActived = true;
      } else {
        this.currentStrokeType = 0;
        if (this.lessonNum === 12) {
          this.colIndex = 2;
        } else {
          this.colIndex = 0;
        }
        this.firstActived = true;
        this.secondActived = false;
      }
      this.rowIndex = 0;
      this.lock = false;
      this.currentSteps = 0;
    },
    handleMove(obj) {
      console.log(555)
      let { lastRowIndex, lastColIndex, currentRowIndex, currentColIndex } =
        obj;
      if (
        this.puzzleItemList[currentRowIndex][currentColIndex].type ===
        this.currentStrokeType
      ) {
        if (
          this.puzzleItemList[currentRowIndex][currentColIndex].index <
          this.currentSteps
        ) {
          this.currentSteps--;
        } else {
          this.currentSteps++;
        }
        if (this.currentSteps === this.strokeStepsArr[this.currentStrokeType]) {
          if (this.currentStrokeType === 1) {
            startConfetti();
            playCorrectSound();

            this.isLastStep = true;
          } else {
            playCorrectSound(true, false);
          }
        }
        this.$emit("avatarMove", obj);
        this.$emit("getTarget", { currentRowIndex, currentColIndex });
      } else {
        this.$emit("avatarMove", obj);
        const stageRow = lastRowIndex;
        const stageCol = lastColIndex;
        lastRowIndex = currentRowIndex;
        lastColIndex = currentColIndex;
        currentRowIndex = stageRow;
        currentColIndex = stageCol;
        this.rowIndex = currentRowIndex;
        this.colIndex = currentColIndex;
        setTimeout(() => {
          this.$emit("avatarMove", {
            lastRowIndex,
            lastColIndex,
            currentRowIndex,
            currentColIndex,
          });
        }, 1000);
      }
    },
    // 游戏点击事件
    leftClick(type) {
      if (type != 1) {
        this.leftClickValue = false;
      } else {
        this.leftClickValue = true;
      }
      console.log("左边");
      if (this.lock) {
        if (0 < this.rowIndex) {
          this.handleMove({
            lastRowIndex: this.rowIndex,
            lastColIndex: this.colIndex,
            currentRowIndex: --this.rowIndex < 0 ? 0 : this.rowIndex,
            currentColIndex: this.colIndex,
          });
        } else {
          this.rowIndex = 0;
        }
      }
      this.leftSocket = Math.random();
    },
    rightClick(type) {
      if (type != 1) {
        this.rightClickValue = false;
        this.resizeGame();
      } else {
        this.rightClickValue = true;
      }
      console.log(this.lock,this.currentStrokeType,this.lessonNum,9999)
      if (!this.lock) {
        this.lock = true;
        this.instructionsDisplay = false;
        // if (this.currentStrokeType === 0) {
        //     this.rowIndex = 0;
        //     this.colIndex = 3;
        //   } else {
        //     this.rowIndex = 0;
        //     this.colIndex = 3;
        //   }
        // 初始化位置
        if (this.lessonNum === 66) {
          if (this.currentStrokeType === 0) {
            this.rowIndex = 0;
            this.colIndex = 3;
          } else {
            this.rowIndex = 0;
            this.colIndex = 3;
          }
        }
        console.log(333)
        this.$emit("firstStep", this.currentStrokeType);
        this.currentSteps++;
      } else {
        if (this.rowIndex < 4) {
          this.handleMove({
            lastRowIndex: this.rowIndex,
            lastColIndex: this.colIndex,
            currentRowIndex: ++this.rowIndex > 4 ? 4 : this.rowIndex,
            currentColIndex: this.colIndex,
          });
        } else {
          this.rowIndex = 4;
        }
      }
      this.rightSocket = Math.random();
    },
    upClick(type) {
      if (type != 1) {
        this.upClickValue = false;
      } else {
        this.upClickValue = true;
      }
      console.log("上边");
      if (this.lock) {
        console.log(this.colIndex);
        if (this.colIndex > 0) {
          this.handleMove({
            lastRowIndex: this.rowIndex,
            lastColIndex: this.colIndex,
            currentRowIndex: this.rowIndex,
            currentColIndex: --this.colIndex < 0 ? 0 : this.colIndex,
          });
        } else {
          this.colIndex = 0;
        }
      }
      this.upSocket = Math.random();
    },
    downClick(type) {
      if (type != 1) {
        this.downClickValue = false;
      } else {
        this.downClickValue = true;
      }
      console.log("下边");
      if (this.lock) {
        if (this.colIndex < 3) {
          this.handleMove({
            lastRowIndex: this.rowIndex,
            lastColIndex: this.colIndex,
            currentRowIndex: this.rowIndex,
            currentColIndex: ++this.colIndex > 3 ? 3 : this.colIndex,
          });
        } else {
          this.colIndex = 3;
        }
      }
      this.downSocket = Math.random();
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    changeTheNumbers(index) {
      this.changeAnimal(index - 1);
      // if (index !== 2) {
      //   this.isLastStep = false;
      // }
    },
  },
};
</script>
<style scoped lang="scss">
.stroke-path-game-container {
  position: relative;
  .stroke-path-game-box {
    width: 100%;
    height: 100%;
    background-color: #f7f4e0;
    border-radius: 58px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    .thumb-nails {
      position: absolute;
      right: 0;
      top: 0;
    }
    .button-control-box {
      height: 25%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      z-index: 5;
      position: relative;
      .control-bg-img {
        position: absolute;
        width: 60%;
        min-width: 400px;
      }
      .instructions {
        width: 40%;
        position: absolute;
        bottom: -20%;
        left: 40%;
        z-index: 6;
        cursor: pointer;
        img {
          width: 40%;
        }
      }
      .button-control-content {
        width: 47%;
        height: 80%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .button-img {
          height: 65%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          z-index: 4;
          img {
            width: 66%;
          }
        }
      }
    }
    .stroke-path-game-content {
      width: 75%;
      height: 65%;
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      .puzzle-game-begin {
        width: 15%;
        height: 100%;
        display: flex;
        align-content: center;
        flex-flow: column;
        .little-bear {
          width: 100%;
          display: flex;
          justify-content: center;
          align-content: center;
          margin-top: 15%;
          .first-animal,
          .second-animal {
            cursor: pointer;
            box-sizing: border-box;
            border: 5px solid #fff;
            border-radius: 15px;
          }
          .border-blue {
            border: 5px solid #224e96;
          }
          .border-yellow {
            border: 5px solid #f4d737;
          }

          img {
            width: 60%;
            margin-right: 10%;
          }
          .arrow {
            width: 20%;
          }
        }
      }
      .lesson-begin-66,.lesson-begin-14,.lesson-begin-26 {
        margin-top: 37%;
      }
      .lesson-begin-58,.lesson-begin-60 {
        .little-bear{
        margin-bottom: 90%;
        }
      }
      
      .puzzle-game-final {
        // flex: 1;
        width: 15%;
        height: 100%;
        display: flex;
        align-content: center;
        flex-flow: column;
        margin-top: 10%;
        .little-bear {
          width: 100%;
          display: flex;
          justify-content: center;
          align-content: center;
          margin-top: 20%;
          img {
            width: 70%;
            margin-left: 10%;
          }
          .arrow {
            width: 20%;
          }
        }
      }
       .lesson-18 {
        margin-top: 10%;
        .little-bear{
          margin-bottom: 60%;
        }
      }
       
      .puzzle-game-content {
        flex: 0 0 auto;
        width: 62%;
        height: 84%;
        // max-height: 470px;
        position: relative;
        .puzzle-game-box {
          // width: 100%;
          height: 100%;
          .puzzle-game-img {
            width: 100%;
            // height: auto;
          }
        }

        .avatar-move-area {
          //   background: chartreuse;
          position: absolute;
          top: 0%;
          left: 0;
          //   opacity: 0.5;
          display: flex;
          //   flex-direction: column;

          .col-area {
            width: 100%;
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;

            .row-area {
              display: flex;
              flex: 1;
              align-items: center;
              .avatar-img {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  box-sizing: border-box;
                  border: 5px solid #fff;
                  border-radius: 15px;
                  width: 80%;
                  z-index: 4;
                }
                .border-blue {
                  border: 5px solid #224e96;
                }
                .border-yellow {
                  border: 5px solid #f4d737;
                }
              }
            }
            .black {
              //   background: firebrick;
            }
          }
        }
        .stroke-step-img-content {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
