<template>
  <div class="game-container">
    <SpeakingSentence
      :titleInfo="titleInfo"
      :buttonList="buttonList"
      :gameList="gameList"
      @initGame="initGame"
      @showSentence="showSentence"
      :lesson="66"
    />
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentenceOne";
export default {
  data() {
    return {
      titleInfo: {
        pinyin: "Kěyǐ...ma？",
        hanzi: "可以……吗?",
      },
      gameList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-66/image-1-1.svg`),
          AchieveImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-66/image-1-2.svg`),
          pinyin: "Wǒmen kěyǐ zuò nàr ma？",
          hanzi: "我们可以坐那儿吗？",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-66/image-2-1.svg`),
          AchieveImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-66/image-2-2.svg`),
          pinyin: "Wǒ kěyǐ chī zhè ge dàngāo ma？",
          hanzi: "我可以吃这个蛋糕吗？",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-66/image-3-1.svg`),
          AchieveImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-66/image-3-2.svg`),
          pinyin: "Wǒ kěyǐ qù gōng yuán ma？",
          hanzi: "我可以去公园吗？",
          isShowSentence: false,
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-66/menuOne-1-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-66/menuOne-2-1.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-66/menuOne-3-1.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
      ],
    };
  },
  components: {
    SpeakingSentence,
  },
  methods: {
    initGame() {
      this.gameList.forEach((item) => {
        item.isShowSentence = false;
      });
    },
    showSentence(index) {
      this.gameList[index - 1].isShowSentence = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
<!-- <template>
  <div class="wordscard-container layout-border">
    <WordCards
      :wordCardList="wordCardList"
      @changeDisplay="handleChangeDisplay"
    />
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: "redColor",
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "liù",
          hanzi: "我们可以坐那儿吗？",
          english: "six",
          image: require(`@/assets/img/12-Vocabulary/lesson-05-liu-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "qī",
          hanzi: "我可以吃这个蛋糕吗？",
          english: "seven",
          image: require(`@/assets/img/12-Vocabulary/lesson-05-qi-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "bā",
          hanzi: "我可以去公园吗？",
          english: "eight",
          image: require(`@/assets/img/12-Vocabulary/lesson-05-ba-bubble.svg`),
        }
      ],
    };
  },
  methods: {
    handleChangeDisplay(index, isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg;
    },
  },
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style> -->
