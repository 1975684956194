<template>
  <!-- layout-container lesson 2 -->
  <div class="lesson66-container">
    <div class="display-page" v-if="pageType === 0">
      <template
        v-for="(item, key, index) in componentsList"
        class="display-page"
      >
        <keep-alive :key="index + 'keep'">
          <transition name="slide-fade" :key="index">
            <component :key="index" :is="item" v-if="index + 1 === pageNum">
            </component>
          </transition>
        </keep-alive>
      </template>
    </div>
    <div class="display-page" v-if="pageType === 1">
      <SyllabusPageB />
    </div>
    <div class="display-page test" v-if="pageType === 2">
      <WhiteBoard />
    </div>
    <div class="whiteBoard-box" v-if="globalWhiteB">
      <!-- 全局白板部分 -->
      <WhiteBoard />
    </div>
  </div>
</template>
<script>
import { lessonMixin } from "@/mixins/lessonDefault.js";
// import SyllabusPageB from "@/components/Course/CoursePage/SyllabusPageB";
import SyllabusPageB from "./3_SyllabusPage_B.vue";
import WhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue";

import LessonPage from "./1_Lesson.vue";
import RankMapPageOne from "./2_RankMapPageOne.vue";
import TargetPage from "./3_Target.vue";

import RocketGame from "./4_RocketGame.vue";
import DragGamePageOne from "./5_DragGameOne.vue";

import ReviewGameOne from "./6_ReviewGameOne.vue";
import ReviewGameTwo from "./7_ReviewGameTwo.vue";
import ReviewGameThree from "./8_ReviewGameThree.vue";
import ReviewGameFour from "./9_ReviewGameFour.vue";
import ReviewGameFive from "./10_ReviewGameFive.vue";

import SpeakingPage from "./11_SpeakingPage.vue";

import RecognitionPageOne from "./12_RecognitionPageOne.vue";
import RecognitionPageTwo from "./13_RecognitionPageTwo.vue";

import PracticeGameOne from "./14_PracticeGameOne.vue";
import PracticeGameTwo from "./15_PracticeGameTwo.vue";
import PracticeGameThree from "./16_PracticeGameThree.vue";
import WriteGameOne from "./17_WriteGameOne.vue";
import WriteGameTwo from "./18_WriteGameTwo.vue";

import LearnReview from "./19_LearnReview.vue";
import RankMapPageTwo from "./20_RankMapPageTwo.vue";
import ByePage from "./21_ByePage.vue";
export default {
  name: "Lesson2",
  components: {
    SyllabusPageB,
    WhiteBoard,
    LessonPage,
    RankMapPageOne,
    TargetPage,
    RocketGame,
    DragGamePageOne,
    ReviewGameOne,
    ReviewGameTwo,
    ReviewGameThree,
    ReviewGameFour,
    ReviewGameFive,
    SpeakingPage,
    RecognitionPageOne,
    RecognitionPageTwo,
    PracticeGameOne,
    PracticeGameTwo,
    PracticeGameThree,
    WriteGameOne,
    WriteGameTwo,
    LearnReview,
    RankMapPageTwo,
    ByePage,
  },
  mixins: [lessonMixin],
  data() {
    return {
      pageNum: 1,
      // lesson2 中的所有page
      componentsList: {
        LessonPage,
        RankMapPageOne,
        TargetPage,
        RocketGame,
        DragGamePageOne,
        ReviewGameOne,
        ReviewGameTwo,
        ReviewGameThree,
        ReviewGameFour,
        ReviewGameFive,
        SpeakingPage,
        RecognitionPageOne,
        RecognitionPageTwo,
        PracticeGameOne,
        PracticeGameTwo,
        PracticeGameThree,
        WriteGameOne,
        WriteGameTwo,
        LearnReview,
        RankMapPageTwo,
        ByePage,
      },
    };
  },
};
</script>
>

<style lang="scss" scoped>
.lesson66-container {
  width: 100%;
  height: 100%;
  .whiteBoard-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    line-height: 1;
  }

  // .page-item {
  //   width: 100%;
  //   height: 100%;

  .display-page {
    width: 100%;
    height: 100%;
    // background-color: #fff;
    border-radius: 56px;
  }
  .test {
    background: #fff;
  }

  // }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
